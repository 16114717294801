<template>
    <div class="website-container">
        <router-view />
    </div>
</template>
<script>
    export default {
        name: 'App',
    };
</script>
<style lang="sass">
    @import "./styles/_variables.sass"

    html, body
        font-family: Raleway, sans-serif
        -webkit-font-smoothing: antialiased
        -moz-osx-font-smoothing: grayscale
        height: 100%
        width: 100%
        padding: 0
        margin: 0

        .website-container
            min-height: 100%
            width: 100%
            display: flex
            flex-direction: column
            align-items: center
            overflow-x: hidden
            overflow-y: scroll
</style>
