import Vue from 'vue';
import App from './App.vue';
import LetItSnow from 'vue-let-it-snow';
import router from "./router.js";

Vue.use(LetItSnow);

Vue.config.productionTip = false;

new Vue({
    router,
    render(h) {
        return h(App);
    }
}).$mount('#app');
