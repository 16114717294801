<template>
    <div class="no-access">
        <h1>
            You do not have permission to access this area.
        </h1>
        <h2>
            You were probably trying to see the
            <RouterLink :to="{name: 'christmas-cards'}">
                Christmas Cards
            </RouterLink>
        </h2>
    </div>
</template>

<script>
    export default {
        name: 'NoAccess'
    };
</script>

<style
    lang="sass"
>
    html, body, .website-container
        background-color: #333333

        .no-access
            height: 100vh
            width: 100vw
            display: flex
            justify-content: center
            align-items: center
            color: whitesmoke
            flex-direction: column

            h1, h2, a
                font-family: Raleway, sans-serif
                color: whitesmoke

</style>
