import Vue from 'vue';
import VueRouter from 'vue-router';
import NoAccess from './views/NoAccess';

Vue.use(VueRouter);

const routes = [
        {
            path: '/',
            component: NoAccess,
        },
        {
            path: '/christmas-cards',
            name: 'christmas-cards',
            component: () => import('./views/ChristmasCards/AllCards.vue'),
            children: [
                {
                    path: '2023',
                    name: 'christmas-cards.2023',
                    component: () => import('./views/ChristmasCards/2023/Landing.vue'),
                },
                {
                    path: '2022',
                    name: 'christmas-cards.2022',
                    component: () => import('./views/ChristmasCards/2022/Landing.vue'),
                },
                {
                    path: '2021',
                    name: 'christmas-cards.2021',
                    component: () => import('./views/ChristmasCards/2021/Landing.vue'),
                },
                {
                    path: '2020',
                    component: () => import('./views/ChristmasCards/2020/Parent.vue'),
                    children: [
                        {
                            path: '',
                            name: 'christmas-cards.2020',
                            component: () => import('./views/ChristmasCards/2020/Landing.vue')
                        },
                        {
                            path: 'highlights',
                            name: 'christmas-cards.2020.highlights',
                            component: () => import('./views/ChristmasCards/2020/Highlights.vue')
                        },
                        {
                            path: 'all-the-deets',
                            name: 'christmas-cards.2020.details',
                            component: () => import('./views/ChristmasCards/2020/Details.vue')
                        },
                    ]
                },
            ]
        },
        {
            path: '*',
            component: NoAccess,
        },

    ],

    router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    });

export default router;
